import React from 'react';
import { Link } from 'react-router-dom';

const items = [
  {
    key: '1',
    label: 'Servicios',
    children: [
      {
        key: '11',
        label: <Link to="/consulting">Consultoría</Link>,
      },
      {
        key: '12',
        label: <Link to="/auditing">Auditoría y Peritaje</Link>,
      },
      // },
      // {
      //   key: '13',
      //   label: 'Política y Normativa',
      //   route: '/politics',
      //   children: [
      //     {
      //       key: '131',
      //       label: 'Creación de Política',
      //     },
      //     {
      //       key: '132',
      //       label: 'Revisión de Política y Normativa',
      //     },
      //     {
      //       key: '133',
      //       label: 'Asesoramiento Regulatorio',
      //     },
      //     {
      //       key: '134',
      //       label: 'IA Responsable',
      //     },
      //     {
      //       key: '135',
      //       label: 'Asesoría y Guianza para Política de IA y Analítica',
      //     },
      //     {
      //       key: '136',
      //       label: 'Política para Tecnología',
      //     },
      //     {
      //       key: '137',
      //       label: 'Ética para IA',
      //     },
      //     {
      //       key: '138',
      //       label: 'Otro',
      //     },
      //   ],
      // },
    ],
  },
  {
    key: '2',
    label: 'Productos',
    children: [
      {
        key: '21',
        label: <Link to="/farec">FaRec: Reconocimiento Facial</Link>,
      },
      // {
      //   key: '21',
      //   label: (
      //     <a
      //       target="_blank"
      //       rel="noopener noreferrer"
      //       href="https://www.antgroup.com"
      //     >
      //       MapIA
      //     </a>
      //   ),
      // },
      //     {
      //       key: '22',
      //       label: (
      //         <a
      //           target="_blank"
      //           rel="noopener noreferrer"
      //           href="https://www.aliyun.com"
      //         >
      //           SummarAIze
      //         </a>
      //       ),
      //     },
    ],
  },
  // {
  //   key: '3',
  //   label: 'Entrenamiento',
  //   children: [
  //     {
  //       key: '31',
  //       label: (
  //         <a
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           href="https://www.antgroup.com"
  //         >
  //           Cursos
  //         </a>
  //       ),
  //     },
  //     {
  //       key: '32',
  //       label: (
  //         <a
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           href="https://www.aliyun.com"
  //         >
  //           Talleres
  //         </a>
  //       ),
  //     },
  //     {
  //       key: '33',
  //       label: (
  //         <a
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           href="https://www.aliyun.com"
  //         >
  //           Pláticas
  //         </a>
  //       ),
  //     },
  //     {
  //       key: '34',
  //       label: (
  //         <a
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           href="https://www.aliyun.com"
  //         >
  //           Conferencias
  //         </a>
  //       ),
  //     },
  //     {
  //       key: '35',
  //       label: (
  //         <a
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           href="https://www.aliyun.com"
  //         >
  //           Evaluación
  //         </a>
  //       ),
  //     },
  //   ],
  // },
  {
    key: '4',
    label: (
      <Link to="/industries">Industrias</Link>
    ),
  },
  // {
  //   key: '5',
  //   label: 'Recursos',
  //   children: [
  //     {
  //       key: '51',
  //       label: (
  //         <a
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           href="https://www.antgroup.com"
  //         >
  //           Investigación
  //         </a>
  //       ),
  //     },
  //     {
  //       key: '52',
  //       label: (
  //         <a
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           href="https://www.antgroup.com"
  //         >
  //           Noticias
  //         </a>
  //       ),
  //     },
  //     {
  //       key: '53',
  //       label: (
  //         <a
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           href="https://www.antgroup.com"
  //         >
  //           Blog
  //         </a>
  //       ),
  //     },
  //   ],
  // },
  {
    key: '6',
    label: (
      <Link to="/aboutus">Nosotros</Link>
    ),
  },
  //   children: [
  //     {
  //       key: '61',
  //       label: (
  //         <a
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           href="https://www.antgroup.com"
  //         >
  //           About Us
  //         </a>
  //       ),
  //     },
  //     {
  //       key: '62',
  //       label: (
  //         <a
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           href="https://www.antgroup.com"
  //         >
  //           Our People
  //         </a>
  //       ),
  //     },
  //     {
  //       key: '63',
  //       label: (
  //         <a
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           href="https://www.antgroup.com"
  //         >
  //           Partners
  //         </a>
  //       ),
  //     },
  //     {
  //       key: '64',
  //       label: (
  //         <a
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           href="https://www.antgroup.com"
  //         >
  //           Responsible
  //         </a>
  //       ),
  //     },
  //   ],
  // },
  {
    key: '7',
    label: <Link to="/contact">Contacto</Link>,
  },
  {
    key: '8',
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://aicraft.mx/"
      >
        Inicia Sesión
      </a>
    ),
  },
];

export default items;

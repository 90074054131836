import React from 'react';
import { SettingOutlined, LockOutlined, RocketOutlined, BlockOutlined, ApiFilled, ContainerFilled, ProductFilled, MergeFilled, VideoCameraFilled, MobileOutlined, BankOutlined, DesktopOutlined } from '@ant-design/icons';

import sre1 from '../../../assets/farec/sre/sre1.webp';
import sre2 from '../../../assets/farec/sre/sre2.webp';
import sre3 from '../../../assets/farec/sre/sre3.webp';
import sre4 from '../../../assets/farec/sre/sre4.webp';
// import sre5 from '../../../assets/farec/sre/sre5.webp';
// import sre6 from '../../../assets/farec/sre/sre6.webp';
import sre1r from '../../../assets/farec/sre/sre1r.webp';
import sre2r from '../../../assets/farec/sre/sre2r.webp';
import sre3r from '../../../assets/farec/sre/sre3r.webp';
import sre4r from '../../../assets/farec/sre/sre4r.webp';
// import sre5r from '../../../assets/farec/sre/sre5r.webp';
// import sre6r from '../../../assets/farec/sre/sre6r.webp';

import P1 from '../../../assets/farec/regulacion/P1.png';
import P2 from '../../../assets/farec/regulacion/P2.png';
import P3 from '../../../assets/farec/regulacion/P3.png';
import P4 from '../../../assets/farec/regulacion/P4.png';
import P5 from '../../../assets/farec/regulacion/P5.png';

import optionsAttendance from '../../../assets/industries/Education/student_profiling.webp';
import optionsAttendanceSports from '../../../assets/industries/Education/violence.webp';
import optionsPrivate from '../../../assets/industries/TH/demand.webp';
import optionsEmployee from '../../../assets/industries/HR/seguimiento.webp';
import optionsSecurity from '../../../assets/industries/Insurance/policy_per.webp';
import optionsMonitor from '../../../assets/industries/CyberSecurity/monitoreo2.webp';

const sreImgs = [
  {
    key: '1',
    image: sre1,
    imageAlt: sre1r,
    title: 'Un sistema eficiente y transformador',
    text: 'FaRec es nuestro sistema avanzado de reconocimiento facial basado en inteligencia artificial para ofrecer una mejor precisión y eficiencia en la identificación de individuos, y transformar los negocios para que mejoren la seguridad, optimicen las operaciones y enriquezcan la experiencia del cliente.',
  },
  {
    key: '2',
    image: sre2,
    imageAlt: sre2r,
    title: '¿Cómo funciona?',
    text: 'FaRec utiliza algoritmos y técnicas de última generación para analizar características faciales con precisión. Nuestro sistema aprende y se adapta, asegurando mejoras en las capacidades de reconocimiento. Además, está diseñado para operar de manera eficiente en varias plataformas, requiriendo recursos de hardware mínimos mientras ofrece un alto rendimiento.',
  },
  {
    key: '3',
    image: sre3,
    imageAlt: sre3r,
    title: 'Seguridad y Privacidad',
    text: 'La seguridad y la privacidad de los datos son nuestra máxima prioridad. Nuestra tecnología de reconocimiento facial está diseñada con robustas características de seguridad que consideran tanto el aspecto técnico como regulatorio para garantizar la protección de la información de tus usuarios.',
  },
  {
    key: '4',
    image: sre4,
    imageAlt: sre4r,
    title: '¿Funciona para mi empresa o institución?',
    text: 'Desde Retail y Finanzas hasta Educación y Deportes, FaRec está diseñado para una variedad de industrias y es tu opción de confianza para aprovechar la tecnología de reconocimiento facial. FaRec se adapta a las necesidades específicas de tu empresa o institución, sin importar el tamaño o sector, para ofrecer soluciones efectivas que impulsan la seguridad y mejoran la experiencia del usuario.',
  },
  // {
  //   key: '5',
  //   image: sre5,
  //   imageAlt: sre5r,
  // },
  // {
  //   key: '6',
  //   image: sre6,
  //   imageAlt: sre6r,
  // },
];

const sreText = [
  {
    key: '1',
    icon: <div className="sreText_Icon"><SettingOutlined className="rotateInfinite" /></div>,
    title: 'Un sistema eficiente y transformador',
    text: 'FaRec es nuestro sistema avanzado de reconocimiento facial basado en inteligencia artificial para ofrecer una mejor precisión y eficiencia en la identificación de individuos, y transformar los negocios para que mejoren la seguridad, optimicen las operaciones y enriquezcan la experiencia del cliente.',
  },
  {
    key: '2',
    icon: <div className="sreText_Icon"><RocketOutlined className="orbitInfinite" style={{ transform: 'rotate(45deg)' }} /></div>,
    title: '¿Cómo funciona?',
    text: 'FaRec utiliza algoritmos y técnicas de última generación para analizar características faciales con precisión. Nuestro sistema aprende y se adapta, asegurando mejoras en las capacidades de reconocimiento. Además, está diseñado para operar de manera eficiente en varias plataformas, requiriendo recursos de hardware mínimos mientras ofrece un alto rendimiento.',
  },
  {
    key: '3',
    icon: <div className="sreText_Icon"><LockOutlined className="shakeInfinite" /></div>,
    title: 'Seguridad y Privacidad',
    text: 'La seguridad y la privacidad de los datos son nuestra máxima prioridad. Nuestra tecnología de reconocimiento facial está diseñada con robustas características de seguridad que consideran tanto el aspecto técnico como regulatorio para garantizar la protección de la información de tus usuarios.',
  },
  {
    key: '4',
    icon: <div className="sreText_Icon"><BlockOutlined /></div>,
    title: '¿Funciona para mi empresa o institución?',
    text: 'Desde Retail y Finanzas hasta Educación y Deportes, FaRec está diseñado para una variedad de industrias y es tu opción de confianza para aprovechar la tecnología de reconocimiento facial. FaRec se adapta a las necesidades específicas de tu empresa o institución, sin importar el tamaño o sector, para ofrecer soluciones efectivas que impulsan la seguridad y mejoran la experiencia del usuario.',
  },
];

const options = [
  {
    key: '1',
    title: 'Control de Asistencia para Exámenes o Evaluaciones',
    text: 'FaRec permite un control eficiente de la asistencia a exámenes mediante el reconocimiento facial. Al momento de la entrada, los estudiantes son identificados rápidamente, lo que minimiza el tiempo de espera y asegura que solo los inscritos en el examen puedan acceder. Además, el sistema registra automáticamente la asistencia, facilitando la gestión administrativa.',
    image: optionsAttendance,
  },
  {
    key: '2',
    title: 'Control de Asistencia a Eventos Deportivos o Multitudinarios',
    text: ' FaRec puede utilizarse para agilizar el proceso de entrada y mejorar la seguridad. Al escanear la cara de los asistentes en las entradas, el sistema verifica su identidad y confirma su derecho de acceso (por ejemplo, entradas VIP). Esto no solo acelera el ingreso, sino que también ayuda a identificar comportamientos sospechosos y a mantener un ambiente seguro.',
    image: optionsAttendanceSports,
  },
  {
    key: '3',
    title: 'Entrada a lugares privados',
    text: 'FaRec se puede implementar en comunidades cerradas, zonas de viviendas, edificios de oficinas, laboratorios, entre otros, para controlar el acceso. Los residentes y empleados pueden ser registrados en el sistema, permitiendo que su entrada sea automática y sin necesidad de llaves o tarjetas. Esto incrementa la seguridad al restringir el acceso solo a personas autorizadas, e identifica personas no autorizadas que accedan a las instalaciones.',
    image: optionsPrivate,
  },
  {
    key: '4',
    title: 'Control de Asistencia de Empleados o Estudiantes',
    text: 'Utilizando FaRec para el control de asistencia en empresas o instituciones educativas, se elimina la necesidad de tarjetas de identificación o registros manuales. Al llegar, los empleados o estudiantes son identificados al instante, registrando su entrada y salida de manera automática. Esto no solo ahorra tiempo, sino que también mejora la precisión en el seguimiento de asistencia.',
    image: optionsEmployee,
  },
  {
    key: '5',
    title: 'Seguridad de Áreas Comunes',
    text: 'FaRec puede ser aplicado en áreas comunes como estacionamientos, jardines, zonas de comida, o pasillos de escuelas y empresas para identificar y monitorear a las personas que se encuentran en esas áreas. Esto permite detectar comportamientos inusuales y asegurar que solo personas autorizadas estén en esos espacios, aumentando la seguridad general de la instalación.',
    image: optionsSecurity,
  },
  {
    key: '6',
    title: 'Monitoreo de Seguridad en Transporte Público',
    text: 'FaRec se puede implementar en estaciones de transporte público para mejorar la seguridad. Al escanear a los pasajeros al ingresar, el sistema puede detectar personas con prohibiciones de acceso o alertar a las autoridades sobre comportamientos sospechosos. Esto contribuye a un ambiente de transporte más seguro y protegido.',
    image: optionsMonitor,
  },
];

const implementacion = [
  // nube
  {
    key: '1',
    name: 'Infraestructura En La Nube',
    content: [
      {
        key: '1',
        title: 'API en la nube',
        icon: <div className="farec_Section_Container_Card_Icon"><ApiFilled /></div>,
      },
      {
        key: '2',
        title: 'Conteinarizado',
        icon: <div className="farec_Section_Container_Card_Icon"><ContainerFilled /></div>,
      },
    ],
  },
  // Local
  {
    key: '2',
    name: 'Infraestructura Local',
    content: [
      {
        key: '3',
        title: 'Aplicación dedicada',
        icon: <div className="farec_Section_Container_Card_Icon"><ProductFilled /></div>,
      },
      {
        key: '4',
        title: 'Modelo Open Source con reentrenamiento',
        icon: <div className="farec_Section_Container_Card_Icon"><MergeFilled /></div>,
      },
    ],
  },
  // Dispositivos inteligentes
  {
    key: '3',
    name: 'Dispositivos Inteligentes',
    content: [
      {
        key: '5',
        title: 'Cámaras IP con capacidades de IA',
        icon: <div className="farec_Section_Container_Card_Icon"><VideoCameraFilled /></div>,
      },
      {
        key: '6',
        title: 'Móviles',
        icon: <div className="farec_Section_Container_Card_Icon"><MobileOutlined /></div>,
      },
      {
        key: '7',
        title: 'Kioskos de Reconocimiento',
        icon: <div className="farec_Section_Container_Card_Icon"><BankOutlined /></div>,
      },
      {
        key: '8',
        title: 'Computadoras',
        icon: <div className="farec_Section_Container_Card_Icon"><DesktopOutlined /></div>,
      },
    ],
  },
];

const regulacion = [
  {
    key: '1',
    title: 'Privacidad',
    class: 'farec_Regulacion_Card1',
    image: P4,
  },
  {
    key: '2',
    title: 'Identificadores faciales no sensibles',
    class: 'farec_Regulacion_Card2',
    image: P1,
  },
  {
    key: '3',
    title: 'Gobierno y seguridad de datos',
    class: 'farec_Regulacion_Card3',
    image: P3,
  },
  {
    key: '4',
    title: 'Gobierno de modelos de IA',
    class: 'farec_Regulacion_Card4',
    image: P2,
  },
  {
    key: '5',
    title: 'Transparencia',
    class: 'farec_Regulacion_Card5',
    image: P5,
  },
];

export {
  sreImgs,
  sreText,
  options,
  implementacion,
  regulacion,
};

import React, { useLayoutEffect } from 'react';
import { createBrowserRouter, RouterProvider, Outlet, useLocation } from 'react-router-dom';
import { Navbar } from './components';
import ErrorPage from './containers/errorPage/error-page';
import HomePage from './containers/homePage/HomePage';
import Consulting from './containers/consulting/Consulting';
import Auditing from './containers/auditing/Auditing';
import Industries from './containers/industries/Industries';
import AboutUs from './containers/aboutus/Aboutus';
import Footer from './components/footer/Footer';
import Contact from './containers/contact/Contact';
import FaRec from './containers/products/Farec2/FaRec';
import './App.css';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant', // Optional if you want to skip the scrolling animation
    });
  }, [location.pathname]);
  return children;
};

const AppLayout = () => (
  <>
    <Navbar />
    <Wrapper>
      <Outlet />
    </Wrapper>
    <Footer />
  </>
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage />, errorElement: <ErrorPage /> },
      { path: '/consulting', element: <Consulting />, errorElement: <ErrorPage /> },
      { path: '/auditing', element: <Auditing />, errorElement: <ErrorPage /> },
      { path: '/industries', element: <Industries />, errorElement: <ErrorPage /> },
      { path: '/politics', element: <Consulting />, errorElement: <ErrorPage /> },
      { path: '/farec', element: <FaRec />, errorElement: <ErrorPage /> },
      { path: '/contact', element: <Contact />, errorElement: <ErrorPage /> },
      { path: '/aboutus', element: <AboutUs />, errorElement: <ErrorPage /> },
      { path: '*', element: <ErrorPage /> },
    ],
  },
]);

const App = () => (
  <div className="App">
    <RouterProvider router={router} />
  </div>
);

export default App;

import React, { useState } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import ScreenCard from '../../components/screenCard/ScreenCard';
import AuditingSection from './auditingSection/AuditingSection';
// import PeritajeSection from './peritajeSection/PeritajeSection';
import './Auditing.css';
// import analisisOpinion from '../../assets/industries/Law/analisis_opinion.webp';
// import peritaje from '../../assets/performance/experience.webp';

gsap.registerPlugin(useGSAP, ScrollTrigger);

export default function Auditing() {
  const [activeSection, setActiveSection] = useState(0);
  const [auditingSectionBtnClicked, setauditingSectionBtnClicked] = useState(false);
  // const [peritajeSectionBtnClicked, setperitajeSectionBtnClicked] = useState(false);

  const handleClick = (num) => {
    setauditingSectionBtnClicked(!auditingSectionBtnClicked);
    if (activeSection !== 0) {
      setTimeout(() => {
        setActiveSection(num);
      }, 1000);
    } else {
      setActiveSection(num);
    }
  };

  return (
    <div className="whiteFont flexColumn auditingContainer">
      <div className="auditingHeader flexColumn section__padding sectionNavMargin" style={{ justifyContent: 'center' }}>
        <div className="heroFontTitle flexColumn auditingHeaderTitle">
          <span className="slidefrombottom heroFontMiniTitle">Nuestros Servicios</span>
          <span className="slidefrombottom pageTitle">Auditoría y Peritaje</span>
        </div>
        <span className="sectionDescription auditingHeaderDesc">
          Confirmamos el uso de IA en resultados y nos aseguramos que las
          soluciones de IA y Datos sean confiables, eficientes, y cumplan
          con las regulaciones adecuadas y mejores prácticas de desarrollo e implementación.
        </span>
        <div className="flexRow" style={{ gap: '1.5rem', marginTop: '1rem' }}>
          <button type="button" className="heroButton" onClick={() => handleClick(1)}>Auditoría</button>
          <button type="button" className="heroButton" onClick={() => handleClick(2)}>Peritaje Especializado</button>
        </div>
      </div>
      {/* <div className="auditingSectionCardContainer">
        <ScreenCard title="Auditoría" description="desc 1" img={analisisOpinion} onclick={handleClick} />
        <ScreenCard title="Peritaje Especializado" description="desc 2" img={peritaje} />
      </div> */}
      {
        activeSection !== 0
        && <AuditingSection sectionBtnClicked={auditingSectionBtnClicked} onSectionBtnClicked={handleClick} activeSection={activeSection} />
      }
    </div>
  );
}

import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import CTA from '../../../components/cta/CTA';
import TextToButton from '../../../components/textToButton/TextToButton.jsx';
import Footer from '../../../components/footer/Footer';
import { peritajeTitle, peritajeDescription, peritajeHighlights, peritajeProcess, peritajeCarousel } from './PeritajeData';
import { auditingTitle, auditingDesc, auditingHighlights, auditingProcess, auditingCarousel } from './AuditingData';
import './AuditingSection.css';
import imgNormatividad from '../../../assets/industries/Law/otro4.webp';
import imgEtica from '../../../assets/consulting/guidance/deployment.jpeg';

gsap.registerPlugin(useGSAP, ScrollTrigger);

export default function AuditingSection({ id, sectionBtnClicked, onSectionBtnClicked, activeSection }) {
  const section = useRef();
  const tl = useRef();

  const handleClick = () => {
    onSectionBtnClicked(0);
  };

  useEffect(() => {
    tl.current = gsap.timeline({
      paused: true,
    });
    tl.current.to(section.current, {
      right: 0,
      duration: 0.4,
      ease: 'easeIn',
    });
    tl.current.to(section.current.children, {
      opacity: 1,
      duration: 0.6,
    });
  }, []);

  useEffect(() => {
    if (sectionBtnClicked) {
      tl.current.restart();
    } else {
      tl.current.reverse();
    }
  }, [sectionBtnClicked]);

  useEffect(() => {
    // 1 = auditing, 2 = peritaje
    if (activeSection === 1) {
      section.current.classList.add('auditingSectionBackground');
      section.current.classList.remove('peritajeSectionBackground');
    } else {
      section.current.classList.remove('auditingSectionBackground');
      section.current.classList.add('peritajeSectionBackground');
    }
  }, [activeSection]);

  const setupTimelines = () => {
    const factorSectionsText = gsap.utils.toArray('.auditingSectionFactorContainer_Text');
    const factorSectionsImg = gsap.utils.toArray('.auditingSectionFactorContainer img');

    // from
    gsap.set('.auditingSectionTitle', {
      visibility: 'hidden',
      opacity: 0,
      x: -100,
    });
    gsap.set('.auditinSectionDescription', {
      visibility: 'hidden',
      opacity: 0,
      y: 120,
    });
    gsap.set('.highlightCard', {
      visibility: 'hidden',
      opacity: 0,
      y: 100,
    });
    gsap.set('.processCard', {
      visibility: 'hidden',
      opacity: 0,
      x: -100,
    });
    gsap.set('.processArrow', {
      visibility: 'hidden',
      opacity: 0,
      x: -100,
    });
    gsap.set(factorSectionsText[0], {
      visibility: 'hidden',
      opacity: 0,
      x: -200,
    });
    gsap.set(factorSectionsText[1], {
      visibility: 'hidden',
      opacity: 0,
      x: 200,
    });
    gsap.set(factorSectionsImg[0], {
      visibility: 'hidden',
      opacity: 0,
      x: 200,
    });
    gsap.set(factorSectionsImg[1], {
      visibility: 'hidden',
      opacity: 0,
      x: -200,
    });

    // to
    gsap.to('.auditingSectionTitle', {
      visibility: 'visible',
      opacity: 1,
      x: 0,
      ease: 'easeIn',
      duration: 1,
    });
    gsap.to('.auditinSectionDescription', {
      visibility: 'visible',
      opacity: 1,
      y: 0,
      ease: 'easeIn',
      duration: 1.5,
    });
    gsap.to('.highlightCard', {
      visibility: 'visible',
      stagger: 0.25,
      opacity: 1,
      y: 0,
      ease: 'easeIn',
      duration: 1,
      scrollTrigger: {
        trigger: '.highlightCard',
        start: 'top 80%',
        // end: 'top 30%',
        // scrub: 1,
      },
    });
    gsap.to('.processCard', {
      visibility: 'visible',
      stagger: 0.3,
      opacity: 1,
      x: 0,
      ease: 'easeIn',
      duration: 0.9,
      scrollTrigger: {
        trigger: '.processCard',
        start: 'top 80%',
      },
    });
    gsap.to('.processArrow', {
      visibility: 'visible',
      delay: 0.15,
      stagger: 0.3,
      opacity: 1,
      x: 0,
      ease: 'easeIn',
      duration: 0.7,
      scrollTrigger: {
        trigger: '.processCard',
        start: 'top 80%',
      },
    });

    gsap.to(factorSectionsText[0], {
      visibility: 'visible',
      opacity: 1,
      x: 0,
      ease: 'easeIn',
      duration: 0.7,
      scrollTrigger: {
        trigger: '.fact1',
        start: 'top 80%',
      },
    });
    gsap.to(factorSectionsText[1], {
      visibility: 'visible',
      opacity: 1,
      x: 0,
      ease: 'easeIn',
      duration: 0.7,
      scrollTrigger: {
        trigger: '.fact2',
        start: 'top 80%',
      },
    });
    gsap.to(factorSectionsImg[0], {
      visibility: 'visible',
      opacity: 1,
      x: 0,
      ease: 'easeIn',
      duration: 0.7,
      scrollTrigger: {
        trigger: '.fact1',
        start: 'top 80%',
      },
    });
    gsap.to(factorSectionsImg[1], {
      visibility: 'visible',
      opacity: 1,
      x: 0,
      ease: 'easeIn',
      duration: 0.7,
      scrollTrigger: {
        trigger: '.fact2',
        start: 'top 80%',
      },
    });
  };

  useGSAP(setupTimelines);

  return (
    <div className="auditingSectionContainer sectionNavMargin" ref={section} id={id}>
      <div className="goBackButton noSelect" onClick={handleClick}>
        <ArrowLeftOutlined />
        Volver
      </div>
      <div className="auditingSectionIntro">
        <span className="auditingSectionTitle">
          { activeSection === 1 ? auditingTitle : peritajeTitle }
        </span>
        <span className="auditinSectionDescription">
          { activeSection === 1 ? auditingDesc : peritajeDescription }
        </span>
      </div>
      <div className="auditingSectionHighlights">
        { activeSection === 1
          && (
            auditingHighlights.map((highlight) => (
              <div className="highlightCard" key={highlight.id}>
                <span className="highlightTitle">{highlight.title}</span>
                <span className="highlightDescription">{highlight.description}</span>
              </div>
            ))
          )}
        { activeSection === 2
          && (
            peritajeHighlights.map((highlight) => (
              <div className="highlightCard" key={highlight.id}>
                <span className="highlightTitle">{highlight.title}</span>
                <span className="highlightDescription">{highlight.description}</span>
              </div>
            ))
          )}
      </div>
      <div className="auditingSectionProcess">
        <span className="auditingSectionProcess_Title sectionTitle">
          Nuestro Proceso
        </span>
        <span className="auditingSectionProcess_Subtitle">
          Nuestro proceso es adaptable a las necesidades de los clientes, y considera etapas clave como:
        </span>
        <div className="auditingSectionHighlights" style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
          { activeSection === 1
            ? (
              auditingProcess.map((step) => (
                <div className="processContainer" key={step.id}>
                  <div className="processCard">
                    <span className="processCardTitle">{step.title}</span>
                    <div className="processCardIcon processCardColorAuditing">
                      { step.icon && <step.icon /> }
                    </div>
                  </div>
                  <div className="processArrow">
                    <ArrowRightOutlined />
                  </div>
                </div>
              ))
            )
            : (
              peritajeProcess.map((step) => (
                <div className="processContainer" key={step.id}>
                  <div className="processCard">
                    <span className="processCardTitle">{step.title}</span>
                    <div className="processCardIcon processCardColorPeritaje">
                      { step.icon && <step.icon /> }
                    </div>
                  </div>
                  <div className="processArrow">
                    <ArrowRightOutlined />
                  </div>
                </div>
              ))
            )}
        </div>
      </div>
      <Carousel
        plugins={[
          'arrows',
          'fastSwipe',
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 3,
            },
          },
        ]}
        breakpoints={{
          640: {
            plugins: [
              'arrows',
              'fastSwipe',
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 1,
                },
              },
            ],
          },
          700: {
            plugins: [
              'arrows',
              'fastSwipe',
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 1.3,
                },
              },
            ],
          },
          1124: {
            plugins: [
              'arrows',
              'fastSwipe',
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 1.6,
                },
              },
            ],
          },
        }}
      >
        { activeSection === 1
          ? (
            auditingCarousel.map((item) => (
              <div className="auditingSectionCarousel_Card" key={item.id}>
                <span className="auditingSectionCarousel_CardTitle">{item.title}</span>
                <span className="auditingSectionCarousel_CardDescription">{item.description}</span>
              </div>
            ))
          )
          : (
            peritajeCarousel.map((item) => (
              <div className="auditingSectionCarousel_Card" key={item.id}>
                <span className="auditingSectionCarousel_CardTitle">{item.title}</span>
                <span className="auditingSectionCarousel_CardDescription">{item.description}</span>
              </div>
            ))
          )}
      </Carousel>
      {
        activeSection === 1
          ? (
            <div className="auditingSectionBottom">
              <div className="auditingSectionFactor">
                <div className="auditingSectionFactorContainer fact1">
                  <div className="auditingSectionFactorContainer_Text">
                    <span className=" heroFontMiniTitle flexRow" style={{ letterSpacing: '1px', marginBottom: '-10px' }}>
                      El factor
                      <span className="gradientText" style={{ fontWeight: 700, marginLeft: '3px' }}>Aicraft</span>
                    </span>
                    <span className="auditingSectionFactorContainer_Title">
                      Normatividad para IA
                    </span>
                    <span className="auditingSectionFactorContainer_Desc">
                      Ofrecemos nuestro propio marco de normatividad de desarrollo e implementación de IA,
                      similar al que sigue Aicraft para asegurar que tus desarrollos se mantengan a
                      la vanguardia. También, nuestros expertos pueden  evaluar tus propios
                      marcos internos de desarrollo.
                    </span>
                  </div>
                  <img src={imgNormatividad} alt="" draggable="false" />
                </div>
                <div className="auditingSectionFactorContainer fact2">
                  <div className="auditingSectionFactorContainer_Text">
                    <span className=" heroFontMiniTitle flexRow" style={{ letterSpacing: '1px', marginBottom: '-10px' }}>
                      El factor
                      <span className="gradientText" style={{ fontWeight: 700, marginLeft: '3px' }}>Aicraft</span>
                    </span>
                    <span className="auditingSectionFactorContainer_Title">
                      IA Ética y Responsable
                    </span>
                    <span className="auditingSectionFactorContainer_Desc">
                      Ofrecemos nuestro código de IA Ética y Responsable para asegurar desarrollos,
                      implementaciones, y usos alineados con las mejores prácticas internacionales.
                    </span>
                  </div>
                  <img src={imgEtica} alt="" draggable="false" />
                </div>
              </div>
              <CTA />
              <div className="sectionColumnContainer textCenter" style={{ width: '100%', margin: '60px 0' }}>
                <span className="sectionTitle">
                  ¿Necesitas una<span className="gradientText" style={{ fontWeight: '700' }}>asesoría o desarrollo</span> más especializado en
                  términos de regulación y normativas?
                </span>
                <span className="sectionDescription">
                  ¡No te preocupes! nuestros
                  expertos de Política y Regulación están aquí para ayudarte a
                  cumplir con tus objetivos.
                </span>
                <div
                  className="featureButtons"
                  style={{ marginTop: '2rem' }}
                >
                  <div className="featuresButtonContainerPr">
                    <Link to="/contact">
                      <button type="button" className="featureBtns">Descubre cómo</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="evalSection" style={{ marginTop: '40px' }}>
              <div
                className="dividerGradientBar gb1"
                id="farec_divider1"
                style={{ width: '60%', height: '3px' }}
              />
              <div className="peritajeContact farec_Demo">
                <span
                  className="farec_Demo_title"
                >
                  {/* ¿Quieres una demostración, tienes una idea de proyecto con reconocimiento facial o quieres actualizar tu sistema actual? */}
                  Solicita una evaluación, o consulta con uno de nuestros expertos y descubre lo que podemos hacer por tu idea.
                </span>
                <div className="farec_Demo_buttonContainer">
                  <TextToButton
                    title="Solicita una evaluación"
                    route="/contact"
                    routeParam="Auditoría y Peritaje, Evaluación de proyecto"
                    icon={<ArrowRightOutlined />}
                  />
                  <TextToButton
                    title="Consulta con un experto"
                    route="/contact"
                    routeParam=""
                    icon={<ArrowRightOutlined />}
                  />
                </div>
              </div>
              <div
                className="dividerGradientBar gb2"
                id="farec_divider2"
                style={{ width: '60%', height: '3px', marginLeft: 'auto', marginRight: '0', marginTop: '3rem', marginBottom: '3rem' }}
              />
            </div>
          )
        }
      <Footer />
    </div>
  );
}

import React, { useEffect, useState, useRef } from 'react';
import gsap from 'gsap';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { useGSAP } from '@gsap/react';
import { CustomEase } from 'gsap/CustomEase';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import AOS from 'aos';
import TextToButton from '../../../components/textToButton/TextToButton.jsx';
import SelectorOption from './selectorOption/selectorOption';
import farecLogo from '../../../assets/farec/farecLogo_NoBg.png';
import { sreImgs, sreText, options, implementacion, regulacion } from './FaRecData.js';
import './FaRec.css';
import 'aos/dist/aos.css';
import farecDemo from '../../../assets/farec/videos/farec_DemoVideo.mp4';

AOS.init();
gsap.registerPlugin(useGSAP, ScrollTrigger, ScrollToPlugin, CustomEase);

CustomEase.create('cubic', '0.83, 0, 0.17, 1');

const mm = gsap.matchMedia();

export default function FaRec() {
  const [activeOption, setActiveOption] = useState('1');
  const [activeCard, setActiveCard] = useState(null);

  const videoRef = useRef(null);
  const tl = useRef();
  const tl2 = useRef();

  let isAnimating = false;

  // function splitTextIntoSpans(selector) {
  //   const elements = document.querySelectorAll(selector);
  //   elements.forEach((element) => {
  //     const theElementt = element;
  //     const text = element.innerText;
  //     const splitText = text.split('').map((char) => `<span>${char === ' ' ? '&nbsp;&nbsp;' : char}</span>`).join('');
  //     theElementt.innerHTML = splitText;
  //   });
  // }

  function initializeCards() {
    const cards = Array.from(document.querySelectorAll('.sreImgcard'));
    gsap.to(cards, {
      y: (i) => `${-15 + 15 * i}%`,
      z: (i) => 15 * i,
      duration: 1,
      ease: 'cubic',
      stagger: -0.1,
    });
  }

  function nextCardSRE() {
    try {
      if (isAnimating) return;

      isAnimating = true;

      const slider = document.querySelector('.sreSlider');
      const cards = Array.from(slider.querySelectorAll('.sreImgcard'));
      const lastCard = cards.pop();
      // const nextCard = cards[cards.length - 1];

      gsap.to(lastCard, {
        y: '+=150%',
        duration: 0.75,
        ease: 'cubic',
        onComplete: () => {
          slider.prepend(lastCard);
          initializeCards();

          setTimeout(() => {
            isAnimating = false;
          }, 1000);
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const video = videoRef.current;
    // const section = videoSection.current;

    tl.current = gsap.timeline();
    tl.current.to(video, {
      // width: '45%',
      position: 'relative',
      duration: 0.2,
      ease: 'easeIn',
      scrollTrigger: {
        trigger: video,
        start: 'top 50%',
        end: 'top 50%',
        onEnter: () => {
          video.play();
          tl.current.play();
        },
      },
    });

    tl2.current = gsap.timeline({
      paused: true,
    });
    tl2.current.to('.farec_Regulacion_Card1', {
      opacity: 1,
      left: '10%',
      duration: 0.2,
      ease: 'linear',
      scrollTrigger: {
        trigger: '.farec_Regulacion',
        start: 'center center',
        onEnter: () => {
          tl2.current.play();
        },
      },
    })
      .to('.farec_Regulacion_Card2', {
        opacity: 1,
        top: '2%',
      })
      .to('.farec_Regulacion_Card3', {
        opacity: 1,
        right: '10%',
      })
      .to('.farec_Regulacion_Card4', {
        opacity: 1,
        bottom: '7%',
      })
      .to('.farec_Regulacion_Card5', {
        opacity: 1,
        bottom: '7%',
      });

    gsap.to('.sreContainer', {
      scrollTrigger: {
        trigger: '.sreContainer',
        start: 'top 65%',
        onEnter: () => {
          initializeCards();
          const interval = setInterval(() => {
            nextCardSRE();
          }, 5500);

          return () => clearInterval(interval);
        },
      },
    });

    mm.add('(min-width: 900px)', () => {
      gsap.to('.sreSlider', {
        scrollTrigger: {
          trigger: '.sreContainer',
          start: 'top 20%',
          end: 'bottom 94%',
          pin: '.sreSlider',
        },
      });
    });

    gsap.utils.toArray('.copy').forEach((item) => {
      ScrollTrigger.create({
        trigger: item,
        start: 'top center',
        onEnter: () => {
          gsap.to(item, {
            animation: 'slideInFromRight 0.6s forwards',
          });
        },
      });
    });
  }, []);

  const onCardClick = (id) => {
    if (activeOption === id) {
      return;
    }
    const content = document.getElementsByClassName('farec_miniSection_OptionContent_Card');
    content[0].classList.add('slideOutRight');
    setTimeout(() => {
      setActiveOption(id);
    }, 100);
  };

  useEffect(() => {
    const content = document.getElementsByClassName('farec_miniSection_OptionContent_Card');
    content[0].classList.remove('slideOutRight');
    content[0].classList.add('slidefromRight');
    if (activeCard != null) {
      activeCard.classList.remove('activeOption');
    }
    const element = document.getElementById(activeOption);
    element.classList.add('activeOption');
    setActiveCard(element);
  }, [activeOption]);

  return (
    <div className="whiteFont" style={{ maxWidth: '100%', overflowX: 'clip' }}>
      <div className="farec_heroContainer section__padding">
        <div className="farecLogoBackground" />
        <img className="farecLogo" src={farecLogo} alt="Aicraft FaRecLogo" />
        <div className="farec_heroTextContainer">
          <span className="farec_heroTitle">
            FaRec
          </span>
          <span className="farec_heroSubTitle">
            La solución inteligente para un reconocimiento facial
            <span className="gradient__text" style={{ marginLeft: '8px', opacity: 0, animation: 'slideInFromBottom 0.7s 1 forwards', animationDelay: '0.9s' }}>seguro y eficiente.</span>
          </span>
          <span className="farec_heroText">
            Experimenta la tecnología de reconocimiento facial de vanguardia diseñada para transformar la forma en que las empresas operan y mejorar su seguridad.
          </span>
        </div>
      </div>
      <div className="sectionNavMargin section__padding farec_Content">
        <div className="sreWrapper">
          <div className="sreTitle sectionTitle" style={{ marginTop: '0', marginBottom: '0' }}>
            <span className="farec_miniSection_Text_Title">
              Rápido, Seguro, Eficiente
            </span>
          </div>
          <div className="sreContainer">
            <div className="sreSlider">
              {
                sreImgs.map((item) => (
                  <div className="sreImgcard" key={item.key}>
                    <img className="sreImgbottom" src={item.image} alt="sreImg" />
                    <img className="sreImgtop" src={item.imageAlt} alt="sreImgAlt" />
                  </div>
                ))
              }
            </div>
            <div className="sreTextContainer">
              {
                sreText.map((item) => (
                  <div className="copy" key={item.key}>
                    <h1>{item.title}</h1>
                    <span>{item.text}</span>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div className="inversetriangleContainer">
          <div className="inverseTriangle" />
        </div>
        <div className="flexRow farec_Video_Section">
          <video className="farec_Video" ref={videoRef} src={farecDemo} muted loop preload="auto" />
          <div className="farec_Video_Text">
            <span className="farec_Video_Text_Title">
              FaRec: Seguridad Garantizada
            </span>
            <span className="farec_Video_Text_Desc">
              Con FaRec nos comprometemos a brindar soluciones de seguridad en espacios clave como escuelas,
              gimnasios y centros de trabajo, asegurando la protección y el bienestar de todos sus usuarios.
            </span>
          </div>
        </div>
        {/* Seccion como aplicar farec en tu comapañia */}
        <div className="farec_Section">
          <div className="farec_miniSection_Text">
            <span
              className="farec_miniSection_Text_Title"
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-delay="500"
              data-aos-duration="600"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
            >
              ¿Cómo aplicar FaRec en tu compañía?
            </span>
            <span
              className="farec_miniSection_Text_Desc"
              data-aos="fade-right"
              data-aos-offset="100"
              data-aos-delay="500"
              data-aos-duration="700"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
            >
              Nuestra tecnología de reconocimiento facial se implementa en diversas aplicaciones,
              permitiendo que cada organización aproveche al máximo sus beneficios, desde la seguridad
              hasta la optimización de procesos. Descubre cómo FaRec puede ser un activo valioso para
              tu empresa o institución.
            </span>
          </div>
          <div className="farec_miniSection_Content">
            <div
              className="farec_miniSection_optionsContainer"
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-delay="500"
              data-aos-duration="700"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
            >
              { options.map((item) => (
                <SelectorOption key={item.key} id={item.key} title={item.title} onCardClick={onCardClick} />
              ))}
            </div>
            <div
              className="farec_miniSection_OptionContent"
              data-aos="fade-left"
              data-aos-offset="100"
              data-aos-delay="500"
              data-aos-duration="700"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
            >
              <div className="dividerGradientBar " style={{ width: '100%', height: '1px', marginLeft: '0', marginRight: '0' }} />
              <div className="farec_miniSection_OptionContent_Card">
                <div className="optionContent_TitleContainer">
                  <span className="farec_miniSection_OptionContent_Card_optionNumber">{ `0${activeOption}`}</span>
                  <span className="farec_miniSection_OptionContent_Card_optionTitle">{options[activeOption - 1].title}</span>
                </div>
                <span className="farec_miniSection_OptionContent_Card_optionDescription">{options[activeOption - 1].text}</span>
                <div className="farec_miniSection_OptionContent_Card_optionImage">
                  <img src={options[activeOption - 1].image} alt="Farec Card Option" />
                </div>
              </div>
              <div className="responsiveCardChange">
                <LeftCircleOutlined />
                <RightCircleOutlined />
              </div>
            </div>
          </div>
        </div>
        {/* Seccion implementacion */}
        <div className="farec_Section" style={{ marginTop: '3rem' }}>
          <div className="farec_miniSection_Text" style={{ margin: 'auto', textAlign: 'center' }}>
            <span
              className="farec_miniSection_Text_Title"
            >
              Implementación
            </span>
            <span
              className="farec_miniSection_Text_Desc"
              style={{ margin: 'auto' }}
            >
              Contamos con diferentes opciones tecnológicas para implementar el modelo de reconocimiento facial. La elección adecuada depende de factores como infraestructura existente y target, recursos de Tl y nivel de personalización deseado
            </span>
          </div>
          <div className="farec_ImplementationSection_Content">
            {
              implementacion.map((item) => (
                <div className="farec_Section_Container" key={item.key}>
                  <span
                    className="farec_Section_Container_Title"
                  >
                    {item.name}
                    <div
                      className="farec_Section_Container_Title_divider"
                    />
                  </span>
                  <div className="farec_Section_Card_Container">
                    {
                      item.content.map((itemContent) => (
                        <div className="farec_Section_Container_Card" key={itemContent.key}>
                          <div className="farec_Section_Container_Card_Icon">
                            {itemContent.icon}
                          </div>
                          <span className="farec_Section_Container_Card_Tile">
                            {itemContent.title}
                          </span>
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        {/* Seccion regulacion */}
        <div className="farec_Regulacion">
          <span className="farec_miniSection_Text_Title">
            Reconocimiento Facial Ético
          </span>
          <span className="farec_miniSection_Text_Desc">
            Al implementar el reconocimiento facial, tomamos en cuenta las políticas y
            recomendaciones éticas para garantizar un uso responsable de los datos y la inteligencia artificial.
          </span>
          <div className="flexRow farec_RegulacionCards">
            {
              regulacion.map((item) => (
                <div key={item.key} className={`farec_Regulacion_Card ${item.class}`}>
                  <img src={item.image} alt="regulacionItem" />
                  <span className="farec_Section_Container_Card_Tile">
                    { item.title }
                  </span>
                </div>
              ))
            }
          </div>
        </div>
        <div className="farec_Demo">
          <div
            className="dividerGradientBar"
            id="farec_divider1"
            style={{ width: '60%', height: '3px', marginLeft: '0', marginRight: 'auto' }}
            data-aos="fade-right"
            data-aos-offset="100"
            data-aos-delay="200"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
          />
          <div className="farec_Demo_Row">
            <div className="farec_Demo_TitleLogo">
              <img className="farecLogo" src={farecLogo} alt="Aicraft FaRecLogo" />
              <span
                className="farec_Demo_title"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-delay="400"
                data-aos-duration="600"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="true"
              >
                {/* ¿Quieres una demostración, tienes una idea de proyecto con reconocimiento facial o quieres actualizar tu sistema actual? */}
                Impulsa tu proyecto con reconocimiento facial o actualiza tu sistema con nuestra experiencia y una demostración personalizada.
              </span>
            </div>
            <div className="farec_Demo_buttonContainer">
              <TextToButton
                title="Solicita una Demostración"
                route="https://outlook.office365.com/book/AicraftAsesoradeProyectos@aicraft.mx/"
                routeParam="FaRec"
                icon={<ArrowRightOutlined />}
                blank
              />
              <TextToButton
                title="Cuentanos sobre tu Proyecto"
                route="/contact"
                routeParam="FaRec"
                icon={<ArrowRightOutlined />}
              />
            </div>
          </div>
          <div
            className="dividerGradientBar"
            id="farec_divider2"
            style={{ width: '60%', height: '3px', marginLeft: 'auto', marginRight: '0', marginTop: '40px' }}
            data-aos="fade-left"
            data-aos-offset="100"
            data-aos-delay="300"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
          />
        </div>
        {/* SECCION PARA REDIRIGIR A AUDITORIA */}
        <div className="sectionColumnContainer textCenter">
          <div className="sectionTitle">
            <span
              className="gradientText"
              style={{ fontWeight: 'bold', fontSize: '2.5rem' }}
              data-aos="fade-zoom-in"
              data-aos-offset="100"
              data-aos-delay="1000"
              data-aos-duration="700"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
            >
              Más Servicios
            </span>
          </div>
          <div
            className="sectionColumnContainer textCenter sectionDescription"
            style={{ gap: '14px' }}
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-delay="1400"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
          >
            <span style={{ maxWidth: '80%' }}>
              ¿Tienes una aplicación de reconocimiento facial y quieres evaluarla, actualizarla,
              o revisar si cumple con las normas de política y ética adecuadas?
            </span>
            <span>
              Conoce nuestros servicios de <b>Auditoria</b> y <b>Asesoramiento de Política y Ética</b>.
            </span>
          </div>
          <div
            className="featureButtons"
            style={{ marginTop: '1rem' }}
            data-aos="fade-zoom-in"
            data-aos-offset="100"
            data-aos-delay="1700"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
          >
            <div className="featuresButtonContainerPr">
              <Link to="/auditing">
                <button type="button" className="featureBtns">Vamos ahí</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="dividerGradientBar" style={{ margin: 'auto' }} />
        {/* SECCION DERECHOS AUTOR ETC */}
        <div className="politicaRep_Section">
          <span>
            * Los ejemplos hacen uso de personas generadas con inteligencia artificial. Estos ejemplos no representan la versión final de algún producto.
          </span>
          <span>
            * Todos los derechos reservados. FaRec es un producto de Aicraft, queda prohibida su reproducción total o parcial, ni su distribución sin el consentimiento previo y por escrito de Aicraft.
          </span>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import './TextToButton.css';
import 'aos/dist/aos.css';

AOS.init();

export default function TextToButton({ title, route, routeParam, icon, blank }) {
  return (
    <div>
      { blank ? (
        <a
          href={route}
          rel="noopener noreferrer"
          target="_blank"
          className="textToBtn_Link"
          data-aos="fade-left"
          data-aos-offset="100"
          data-aos-delay="400"
          data-aos-duration="600"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          <button type="button" className="textToBtn_100">
            <span>{title}</span>
            {icon && (
              icon
            )}
          </button>
        </a>
      ) : (
        <Link
          to={route}
          state={{ industry: routeParam }}
          className="textToBtn_Link"
          data-aos="fade-left"
          data-aos-offset="100"
          data-aos-delay="400"
          data-aos-duration="600"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          <button type="button" className="textToBtn_100">
            <span>{title}</span>
            {icon && (
              icon
            )}
          </button>
        </Link>
      )}
    </div>
  );
}

import { FundProjectionScreenOutlined, DatabaseOutlined, ExceptionOutlined, CommentOutlined } from '@ant-design/icons';

const peritajeTitle = 'Peritaje Especializado';
const peritajeDescription = 'Servicios para contextos legales o de resolución de conflictos que requieran el análisis u opinión imparcial de un experto sobre el uso de IA, soluciones de datos, o modelos que sean relevantes en el conflicto,  proporcionando evidencia experta y opiniones técnicas.';

const peritajeHighlights = [
  {
    id: 1,
    title: 'Análisis reactivo',
    description: 'Revisión y respuesta a una situación o problema específico, cuando ya ha ocurrido un conflicto, incidente o litigio.',
    img: 'peritaje',
  },
  {
    id: 2,
    title: 'Informe Detallado',
    description:
      'Analisis detallado, claro, y objetivo de un experto que puede ser usado en un litigio para determinar si la IA, modelo o solución de datos causó algún tipo de daño, error, discriminación, o resultado de interés.',
    img: 'peritaje',
  },
  {
    id: 3,
    title: 'Imparcialidad y Responsabilidad',
    description: 'Opinión experta objetiva sobre si la tecnología se usó correctamente, si siguió protocolos adecuados, o si cometió algún fallo en su uso.',
    img: 'peritaje',
  },
];

const peritajeProcess = [
  {
    id: 1,
    title: 'Consulta Inicial',
    icon: FundProjectionScreenOutlined,
  },
  {
    id: 2,
    title: 'Evaluación Inicial ',
    icon: DatabaseOutlined,
  },
  {
    id: 3,
    title: 'Análisis Forense de algoritmos, fallos, IA, soluciones de datos, o tecnología',
    icon: ExceptionOutlined,
  },
  {
    id: 4,
    title: 'Informe Pericial detallado e imparcial',
    icon: CommentOutlined,
  },
  {
    id: 5,
    title: 'Consultas y Asistencia sobre conclusiones ',
    icon: CommentOutlined,
  },
];

const peritajeCarousel = [
  {
    id: 1,
    title: 'Análisis para Litigios',
    description: 'Examen técnico de algoritmos y sistemas de IA para identificar fallos, sesgos o comportamientos no deseados que puedan haber ocasionado daños o conflictos',
    img: 'consultoria',
  },
  {
    id: 2,
    title: 'Evaluación de Sesgo y Discriminación',
    description: 'Análisis exhaustivo para determinar si un sistema de IA, modelo, o solución de datos discrimina por raza, género u otros factores',
    img: 'consultoria',
  },
  {
    id: 3,
    title: 'Peritaje en Auditorías de Seguridad',
    description: 'Evaluación de la seguridad de los sistemas de IA y datos, con el objetivo de detectar vulnerabilidades que puedan haber facilitado brechas de datos o fallos operativos',
    img: 'consultoria',
  },
  {
    id: 4,
    title: 'Análisis Forense',
    description: 'Estudio detallado del funcionamiento de algoritmos y sistemas para determinar cómo y por qué fallaron en situaciones críticas',
    img: 'consultoria',
  },
  {
    id: 5,
    title: 'Asesoría Técnica para Juicios o resultados legales',
    description: 'Opiniones expertas para abogados, jueces, o personajes relacionados en casos que involucran IA, algoritmos, tecnología, o soluciones de datos para determinar malas prácticas, usos, fallos, entre otros, proporcionando informes técnicos y testimonios en el tribunal',
    img: 'consultoria',
  },
];

export { peritajeTitle, peritajeDescription, peritajeHighlights, peritajeProcess, peritajeCarousel };

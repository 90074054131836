import { FundProjectionScreenOutlined, DatabaseOutlined, ExceptionOutlined, CommentOutlined } from '@ant-design/icons';

const auditingTitle = 'Auditoría de IA';
const auditingDesc = 'Un proceso preventivo y sistemático para evaluar y verificar el desempeño, la ética y el cumplimiento normativo de los sistemas de IA y datos, con el objetivo de garantizar una operación eficiente, transparente, justa, y que cumpla con las regulaciones y estándares legales o corporativos.';

const auditingHighlights = [
  {
    id: 1,
    title: 'Compliance',
    description: 'Revisión y asesoría para el debido cumplimiento de la normativa aplicable a sus modelos e implementaciones de IA y datos  para las distintas industrias y marcos regulatorios.',
    img: 'auditing',
  },
  {
    id: 2,
    title: 'Reporte Detallado',
    description:
      'Análisis detallado y claro para mejor toma de acciones correctivas y mejoras.',
    img: 'peritaje',
  },
  {
    id: 3,
    title: 'Identificación de Riesgos y Mejoras',
    description: 'Hallazgos y recomendaciones de expertos para implementar mejoras y reducir riesgos.',
    img: 'peritaje',
  },
  {
    id: 4,
    title: 'Mantenerse Actualizado',
    description: 'Recibe opinión experta para implementar las últimas innovaciones en IA, Machine Learning y tecnologías emergentes.',
    img: 'peritaje',
  },
];

const auditingProcess = [
  {
    id: 1,
    title: 'Consulta Inicial y Definición de Objetivos y Alcance',
    icon: FundProjectionScreenOutlined,
  },
  {
    id: 2,
    title: 'Revisión de Modelos, Datos, o Implementaciones',
    icon: DatabaseOutlined,
  },
  {
    id: 3,
    title: 'Identificación de riesgos y generación de reporte',
    icon: ExceptionOutlined,
  },
  {
    id: 4,
    title: 'Consultas de seguimiento para recomendaciones y mejoras',
    icon: CommentOutlined,
  },
];

const auditingCarousel = [
  {
    id: 1,
    title: 'Modelos de IA y Analítica',
    description: 'Evaluamos lo actualizado de sus modelos, su performance, equidad, y estandares éticos, regulatorios y de desarrollo',
    img: 'consultoria',
  },
  {
    id: 2,
    title: 'Calidad y Governanza de Datos',
    description: 'Nos aseguramos que sus estructuras de datos sean apropiadas, confiables, y estén listas para ser usadas para crear soluciones de IA, Analítica, Ciencia de Datos, Dashboards, o para migrar a servicios en la nube',
    img: 'consultoria',
  },
  {
    id: 3,
    title: 'Regulación y Estándares',
    description: 'Mantente actualizado y alineado a los estandares y regulaciones para datos, IA, y modelos analíticos, tales como GDPR, ISO, o AI Act, y a regulaciones de aplicaciones especificas como Basilea, IFRS-9, entre otras, así como a regulaciones locales como las de la CNBV, LFPDPPP, y más',
    img: 'consultoria',
  },
  {
    id: 4,
    title: 'Eficiencia, Sezgos, y Equidad',
    description: 'Evauaciones de modelos que mitigan sezgos, buscan uso equitativo, y optimizan su rendimiento, asegurando un mejor cumplimientos de los objetivos de negocio',
    img: 'consultoria',
  },
  {
    id: 5,
    title: 'Evaluación de Infraestructura de Datos y Soluciones Tecnológicas',
    description: 'Revisamos la infraestructura tecnológica utilizada para las soluciones de IA, asegurando su capacidad para soportar la carga y los requisitos de rendimiento necesarios',
    img: 'consultoria',
  },
];

export { auditingTitle, auditingDesc, auditingHighlights, auditingProcess, auditingCarousel };

import React from 'react';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import CarouselComponent from '../../../components/Carousel/Carousel.jsx';
import carouselItems from '../../../components/Carousel/carouselItems.js';
import './header.css';
import 'aos/dist/aos.css';

AOS.init();

const contentStyle = {
  margin: 0,
  minHeight: '175px',
  height: 'fit-content',
  color: '#fff',
  background: 'transparent',
  marginTop: '50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
};

const Header = () => (
  <div className="gpt3__header" id="home">
    <div
      className="gpt3__header-content"
      data-aos="fade-zoom-in"
      data-aos-offset="0"
      data-aos-delay="100"
      data-aos-duration="1200"
      data-aos-easing="ease-out-sine"
      data-aos-mirror="true"
      data-aos-once="true"
    >
      <div className="carouselSizing">
        <CarouselComponent dotsEnabled effect="fade">
          {
            carouselItems.map((item) => (
              <div key={item.key}>
                <div style={contentStyle}>
                  <span style={{ fontSize: '2.1rem', fontWeight: 'bold' }} className="">{item.title}</span>
                  <p>
                    {item.description}
                  </p>
                </div>
              </div>
            ))
          }
        </CarouselComponent>
      </div>
      <div className="header_action-buttons">
        <div className="header_btnContactContainer">
          <Link to="/contact">
            <button type="button" className="header_action-buttons_button header_btnContact">Contáctanos</button>
          </Link>
        </div>
        <div className="header_btnBusinessContainer">
          <a
            href="https://outlook.office365.com/book/AicraftAsesoradeProyectos@aicraft.mx/"
            rel="noopener noreferrer"
            target="_blank"
            className="textToBtn_Link"
          >
            <button type="button" className="header_action-buttons_button header_btnBusiness">Agenda una sesión</button>
          </a>
        </div>
      </div>
    </div>
    <div className="gpt3__header-image" />
    <input type="text" name="username" placeholder="Your username" tabIndex="-1" autoComplete="new-password" style={{ position: 'absolute', left: '-999999999px' }} />
  </div>
);

export default Header;

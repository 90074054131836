import React from 'react';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import { RightCircleFilled } from '@ant-design/icons';
import HorizontalSection from '../../../components/horizontalSection/HorizontalSection';
import ConsultingServicesData from '../ConsultingData';
import './Services.css';
import '@brainhubeu/react-carousel/lib/style.css';
import 'aos/dist/aos.css';

AOS.init();

const Services = ({ orientation }) => (
  <div className={`consultingContent ${orientation === 'reverse' ? 'reverseRow' : ''}`}>
    <div
      className="consultingCarouselSideTitle"
      data-aos="fade-up"
      data-aos-offset="200"
      data-aos-delay="50"
      data-aos-duration="700"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
    >
      <h1 className="consultingCarouselTitle">Nuestras Especialidades</h1>
      <span className="consultingCarouselDesc">
        Nos especializamos en transformar desafíos de datos en ventajas competitivas para tu organización, descubre nuestras principales áreas de consultoría
      </span>
      <Link to="/industries">
        <span className="consultingCarouselLink">
          <RightCircleFilled style={{ fontSize: '30px' }} />
          <span className="linkText">
            Industrias
          </span>
        </span>
      </Link>
      <Link to="/contact">
        <span className="consultingCarouselLink">
          <RightCircleFilled style={{ fontSize: '30px' }} />
          <span className="linkText">
            Contacto
          </span>
        </span>
      </Link>
    </div>

    <div
      className="consultingCarouselContainer"
      data-aos="fade-left"
      data-aos-offset="200"
      data-aos-delay="50"
      data-aos-duration="700"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
    >
      <Carousel
        plugins={[
          'arrows',
          'fastSwipe',
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 2.3,
            },
          },
        ]}
        breakpoints={{
          640: {
            plugins: [
              'arrows',
              'fastSwipe',
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 1,
                },
              },
            ],
          },
          700: {
            plugins: [
              'arrows',
              'fastSwipe',
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 1.3,
                },
              },
            ],
          },
          1124: {
            plugins: [
              'arrows',
              'fastSwipe',
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 1.6,
                },
              },
            ],
          },
        }}
      >
        {ConsultingServicesData.map((item, index) => (
          <HorizontalSection
            key={index}
            title={item.mini}
            content={item.title}
            propImg={item.image}
          />
        ))}
      </Carousel>
    </div>
  </div>
);

export default Services;

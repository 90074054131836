import React from 'react';
import { Carousel, ConfigProvider } from 'antd';
import './Carousel.css';

const CarouselComponent = ({ children, dotsPosition = 'top', dotsEnabled = true, effect = 'scrollx', showArrows = true }) => (
  <ConfigProvider
    theme={{
      components: {
        Carousel: {
          dotWidth: 10,
          dotHeight: 10,
          dotActiveWidth: 10,
        },
      },
    }}
  >
    <div className="w-screen h-80 carouselComponent">
      <Carousel arrows={showArrows} infinite dots={dotsEnabled} dotPosition={dotsPosition} effect={effect} autoplay autoplaySpeed={3500} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        { children }
      </Carousel>
    </div>
  </ConfigProvider>
);

export default CarouselComponent;

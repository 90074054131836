import React from 'react';
import './selectorOption.css';

export default function SelectorOption({ id, title, onCardClick }) {
  function active() {
    onCardClick(id);
  }

  return (
    <div id={id} className="farec_miniSection_option noSelect" onClick={active}>
      <span>
        {title}
      </span>
    </div>
  );
}
